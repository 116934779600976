export const experienceData = [
    {
        id: 1,
        company: 'Myanamr Web Designer Co.,Ltd',
        jobtitle: 'Junior Wordpres Developer',
        startYear: '2017',
        endYear: '2019'
    },
    {
        id: 2,
        company: 'Next Innovation Co.,Ltd',
        jobtitle: 'Senior Web developer',
        startYear: '2019',
        endYear: '2021'
    },
    {
        id: 3,
        company: 'Kaung Khant Aung Myint Co.,ltd',
        jobtitle: 'Team Leader Web And Application developer',
        startYear: '2021',
        endYear: 'current'
    },
    
]